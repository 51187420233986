// Generated by Framer (4c611f3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/iXm_VjsGD";

const cycleOrder = ["zO31r8QxE"];

const variantClassNames = {zO31r8QxE: "framer-v-12ehoaw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, twGU1AVLN: title ?? props.twGU1AVLN ?? "Webinars"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, twGU1AVLN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "zO31r8QxE", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-344h5", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-12ehoaw", className)} data-framer-name={"Granite link"} layoutDependency={layoutDependency} layoutId={"zO31r8QxE"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-weight": "500", "--framer-line-height": "1.6em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}><Link href={"https://applause.ewebinar.com"} openInNewTab smoothScroll={false}><motion.a className={"framer-styles-preset-eh9z0o"} data-styles-preset={"iXm_VjsGD"}>Webinars</motion.a></Link></motion.p></React.Fragment>} className={"framer-1ixwfpx"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"v03_BuLm2"} style={{"--extracted-r6o4lv": "var(--token-2c2cd49b-a4b3-4422-bbe2-647c6a818fa2, rgb(102, 102, 102))", "--framer-paragraph-spacing": "0px"}} text={twGU1AVLN} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-344h5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-344h5 .framer-31m4p5 { display: block; }", ".framer-344h5 .framer-12ehoaw { height: 26px; overflow: visible; position: relative; width: 72px; }", ".framer-344h5 .framer-1ixwfpx { flex: none; height: auto; left: 0px; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 72
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"twGU1AVLN":"title"}
 */
const FramervtMxPy8gu: React.ComponentType<Props> = withCSS(Component, css, "framer-344h5") as typeof Component;
export default FramervtMxPy8gu;

FramervtMxPy8gu.displayName = "Granite link";

FramervtMxPy8gu.defaultProps = {height: 26, width: 72};

addPropertyControls(FramervtMxPy8gu, {twGU1AVLN: {defaultValue: "Webinars", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramervtMxPy8gu, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/vtMxPy8gu:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf", weight: "500"}, ...sharedStyle.fonts])